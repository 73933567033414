<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="passwordValidation">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Informe a Nova Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha"
                vid="Senha"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Nova Senha"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
          <!--/ new password -->

          <!-- retype password -->
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Confirme a Nova Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Nova Senha"
                rules="required|confirmed:Senha"
              >            
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Nova Senha"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>              
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <!-- submit button -->  
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              <b-spinner v-if="isLoading" small />
              <span v-else>Salvar</span>
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'
import { required, confirmed } from '@validations'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, 
  BCol, BCard, BInputGroup, BInputGroupAppend,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import services from '@/services'

localize('pt_BR', ptBR)

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BInputGroupAppend,
        BSpinner,
        ValidationProvider,
        ValidationObserver
    },

    directives: {
        Ripple,
    },

    data() {
        return {
            isLoading: false,
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',
            required,
            confirmed,
        }
    },

    computed: {
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },

    methods: {

        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
        },

        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
        },

        async validationForm() {
            this.$refs.passwordValidation.validate().then(success => {
                if (success) {
                    this.saveNewPassword()
                }
                else {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Ops!',
                          icon: 'XIcon',
                          variant: 'danger',
                          text: 'Senha não atualizada, verifique as mensagens de erro!'
                      },
                  })                  
                }
            })
        },

        async saveNewPassword () {
            this.isLoading = true
            const { errors } = await services.auth.changeUserPassword(this.newPasswordValue)
            this.isLoading = false

            if (errors) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Ops!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: 'Não foi possível alterar sua senha!'
                    },
                })
            } 
            else {
                this.newPasswordValue = ''
                this.RetypePassword = ''
                this.$refs.passwordValidation.reset()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: '',
                        icon: 'EditIcon',
                        variant: 'success',
                        text: 'Senha atualizada com sucesso!'
                    },
                })                
            }              

        }

    },
}
</script>
