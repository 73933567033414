<template>
	<b-card>
		<!-- media -->
		<b-media no-body>
			<b-media-aside>
				<b-link>
					<b-avatar
						size="60"
						variant="light-primary"
						:src="customer.image"
						rounded="sm"
					/>
					<!-- <b-img
            ref="previewEl"
            rounded
            :src="customer.image"
            height="80"
          /> -->
				</b-link>
				<!--/ avatar -->
			</b-media-aside>
		</b-media>
		<!--/ media -->

		<!-- form -->
		<validation-observer ref="formValidation">
			<b-form class="mt-2" @submit.prevent="validationForm">
				<b-row>
					<!-- Primeiro Nome -->
					<b-col sm="6">
						<b-form-group label="Primeiro Nome" label-for="first-name">
							<validation-provider
								#default="{ errors }"
								name="Primeiro Nome"
								rules="required"
							>
								<b-form-input v-model="customer.first_name" name="first-name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- E-mail -->
					<b-col sm="6">
						<b-form-group label="E-mail" label-for="mail">
							<b-form-input v-model="customer.email" name="email" disabled />
						</b-form-group>
					</b-col>

					<!-- Nome Completo -->
					<b-col sm="12">
						<b-form-group label="Nome Completo" label-for="name">
							<validation-provider
								#default="{ errors }"
								name="Nome Completo"
								rules="required"
							>
								<b-form-input v-model="customer.name" name="name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-button
							type="submit"
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mt-2 mr-1"
						>
							<b-spinner v-if="isLoading" small />
							<span v-else>Salvar</span>
						</b-button>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'
import { required } from '@validations'
import {
	BButton,
	BForm,
	BFormGroup,
	BFormInput,
	BRow,
	BCol,
	BCard,
	BMedia,
	BMediaAside,
	BLink,
	BAvatar,
	BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { updateCustomer } from '@/services/customer'

localize('pt_BR', ptBR)

export default {
	components: {
		BButton,
		BForm,
		BAvatar,
		BFormGroup,
		BFormInput,
		BRow,
		BCol,
		BCard,
		BMedia,
		BMediaAside,
		BLink,
		BSpinner,
		ValidationProvider,
		ValidationObserver,
	},

	directives: {
		Ripple,
	},

	data() {
		return {
			isLoading: false,
			required,
			customer: null,
			optionsLocal: {},
			profileFile: null,
		}
	},

	methods: {
		async validationForm() {
			this.$refs.formValidation.validate().then(success => {
				if (success) {
					this.handleUpdateCustomer()
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Ops!',
							icon: 'XIcon',
							variant: 'danger',
							text: 'Dados do usuário não atualizados!',
						},
					})
				}
			})
		},

		async handleUpdateCustomer() {
			this.isLoading = true
			const { errors } = await updateCustomer(
				this.customer,
				this.$apolloProvider.defaultClient
			)
			this.isLoading = false

			if (errors) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Não foi atualizar seus dados!',
					},
				})
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: '',
						icon: 'EditIcon',
						variant: 'success',
						text: 'Dados atualizados com sucesso!',
					},
				})
			}
		},
	},

	computed: {},

	created() {
		this.customer = store.state.customer.loggedCustomer
	},
}
</script>
